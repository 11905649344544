@import "../../variables.modules";

i {
  padding-left: 0.5rem;

  &.red {
    color: $red;
  }

  &.amber {
    color: $yellow;
  }

  &.green {
    color: $green;
  }
}

.terminal {
  p, li {
    margin: 1rem 0;
    color: $white;
  }

  a, a:visited {
    animation: changeColors 3s infinite;
    font-weight: bold;
  }
}

.terminalTitle {
  font-family: 'Courier New', Courier, monospace;
  margin: auto;
  display: table;
}

.terminalIcons {
  position: absolute;
}

@keyframes changeColors {
  0% {
    color: #00a47f;
  }
  33.3% {
    color: #1d91e3;
  }
  66.6% {
    color: #d419fe;
  }
  100% {
    color: #00a47f;
  }
}

//Tilt-in style animation

.tiltInLeft {
	-webkit-animation: tiltInLeft 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: tiltInLeft 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

 @-webkit-keyframes tiltInLeft {
  0% {
    -webkit-transform: rotateX(-30deg) translateX(-300px) skewX(-30deg);
            transform: rotateX(-30deg) translateX(-300px) skewX(-30deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg) translateX(0) skewX(0deg);
            transform: rotateX(0deg) translateX(0) skewX(0deg);
    opacity: 1;
  }
}
@keyframes tiltInLeft {
  0% {
    -webkit-transform: rotateX(-30deg) translateX(-300px) skewX(-30deg);
            transform: rotateX(-30deg) translateX(-300px) skewX(-30deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg) translateX(0) skewX(0deg);
            transform: rotateX(0deg) translateX(0) skewX(0deg);
    opacity: 1;
  }
}
