@import "../variables.modules";

nav {
  transition: $transition;
  width: 100%;
  padding: 10px;
}

.active {
  border-bottom: 5px solid;
  border-image-slice: 1;
}

li {
  transition: $transition;

  &:hover {
    transform: translateY(-3px);
    transition: $transition;
  }
}

.headline{
  font-family: cursive;
}
.link:hover {
  color: unset;
}