@import "../../variables.modules.scss";

.permission {
  margin: auto;
  text-align: center;
  padding: 20px;
}

.reports {
  margin: auto;
  text-align: center;
  text-align: -webkit-center;
}

.headings {
  font-size: x-large;
  margin: 20px;
}
