@import "../../variables.modules.scss";

$color_1: rgb(50,50,50);
$color_2: $red;
$font-family_1: 'Open Sans', sans-serif;
$border-right-color_1: rgb(248,248,248);
$border-bottom-color_1: rgb(255,255,255);
$border-left-color_1: rgb(248,248,248);
$timelineBgColor: #27242f;

/* ================ The Timeline ================ */
/* ================ Timeline Media Queries ================ */
// body {
// 	margin: 0;
// 	padding: 0;
// 	background: rgb(230,230,230);
// 	color: $color_1;
// 	font-family: $font-family_1;
// 	font-size: 112.5%;
// 	line-height: 1.6em;
// }
.timelineTitle {
	font-family: 'Courier New', Courier, monospace;
	margin: auto;
	display: table;
  }

.timelineBox {
    margin: auto;
    
    // text-align: center;
}

.paper {
    // padding: 1.5rem;
    // color: $white;
    background-color: $timelineBgColor !important;
    border-radius: 0.5rem;
    
}

.timeline {
	position: relative;
	width: 660px;
	margin: 0 auto;
	margin-top: 20px;
	padding: 1em 0;
	list-style-type: none;
	&:before {
		position: absolute;
		left: 50%;
		top: 0;
		content: ' ';
		display: block;
		width: 6px;
		height: 100%;
		margin-left: -3px;
		background: rgb(80,80,80);
		background: -moz-linear-gradient(top, rgba(80,80,80,0) 0%, rgb(80,80,80) 8%, rgb(80,80,80) 92%, rgba(80,80,80,0) 100%);
		background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(30,87,153,1)), color-stop(100%,rgba(125,185,232,1)));
		background: -webkit-linear-gradient(top, rgba(80,80,80,0) 0%, rgb(80,80,80) 8%, rgb(80,80,80) 92%, rgba(80,80,80,0) 100%);
		background: -o-linear-gradient(top, rgba(80,80,80,0) 0%, rgb(80,80,80) 8%, rgb(80,80,80) 92%, rgba(80,80,80,0) 100%);
		background: -ms-linear-gradient(top, rgba(80,80,80,0) 0%, rgb(80,80,80) 8%, rgb(80,80,80) 92%, rgba(80,80,80,0) 100%);
		background: linear-gradient(to bottom, rgba(80,80,80,0) 0%, rgb(80,80,80) 8%, rgb(80,80,80) 92%, rgba(80,80,80,0) 100%);
		// z-index: 5;
	}
	li {
		padding: 1em 0;
		&:after {
			content: "";
			display: block;
			height: 0;
			clear: both;
			visibility: hidden;
		}
	}
}
.directionL {
	position: relative;
	width: 300px;
	float: left;
	text-align: right;
	.flag {
		-webkit-box-shadow: -1px 1px 1px rgba(0,0,0,0.15), 0 0 1px rgba(0,0,0,0.15);
		-moz-box-shadow: -1px 1px 1px rgba(0,0,0,0.15), 0 0 1px rgba(0,0,0,0.15);
		box-shadow: -1px 1px 1px rgba(0,0,0,0.15), 0 0 1px rgba(0,0,0,0.15);
        color: $color_1;
		&:before {
			position: absolute;
			top: 50%;
			right: -40px;
			content: ' ';
			display: block;
			width: 12px;
			height: 12px;
			margin-top: -10px;
			background: #fff;
			border-radius: 10px;
			border: 4px solid rgb(255,80,80);
			z-index: 10;
		}
		&:after {
			content: "";
			position: absolute;
			left: 100%;
			top: 50%;
			height: 0;
			width: 0;
			margin-top: -8px;
			border: solid transparent;
			border-left-color: $border-left-color_1;
			border-width: 8px;
			pointer-events: none;
		}
	}
	.timeWrapper {
		float: left;
	}
}
.directionR {
	position: relative;
	width: 300px;
	float: right;
	.flag {
		-webkit-box-shadow: 1px 1px 1px rgba(0,0,0,0.15), 0 0 1px rgba(0,0,0,0.15);
		-moz-box-shadow: 1px 1px 1px rgba(0,0,0,0.15), 0 0 1px rgba(0,0,0,0.15);
		box-shadow: 1px 1px 1px rgba(0,0,0,0.15), 0 0 1px rgba(0,0,0,0.15);
        color: $color_1;
		&:before {
			position: absolute;
			top: 50%;
			right: -40px;
			content: ' ';
			display: block;
			width: 12px;
			height: 12px;
			margin-top: -10px;
			background: #fff;
			border-radius: 10px;
			border: 4px solid rgb(255,80,80);
			z-index: 10;
			left: -40px;
		}
		&:after {
			content: "";
			position: absolute;
			right: 100%;
			top: 50%;
			height: 0;
			width: 0;
			margin-top: -8px;
			border: solid transparent;
			border-right-color: $border-right-color_1;
			border-width: 8px;
			pointer-events: none;
		}
	}
	.timeWrapper {
		float: right;
	}
	.desc {
		margin: 1em 0 0 0.75em;
	}
}
.flagWrapper {
	position: relative;
	display: inline-block;
	text-align: center;
}
.flag {
	position: relative;
	display: inline;
	background: rgb(248,248,248);
	padding: 6px 10px;
	border-radius: 5px;
	font-weight: 600;
	text-align: left;
    color: $color_1;
}
.timeWrapper {
	display: inline;
	line-height: 1em;
	font-size: 12px;
	color: $color_2;
	vertical-align: middle;
}
.time {
	display: inline-block;
	padding: 4px 6px;
	//background: rgb(248,248,248);
}
.desc {
	margin: 1em 0.75em 0 0;
	font-size: 16px;
	font-style: italic;
	line-height: 1.2rem;
    color: $white;
	font-family: 'Courier New', Courier, monospace;


}
@media screen and (max-width: 780px) {
	.timeline {
		width: 100%;
		padding: 4em 0 1em 0;
		li {
			padding: 2rem 0;
		}
	}
	.directionL {
		float: none;
		width: 100%;
		text-align: center;
		.flag {
			&:before {
				position: absolute;
				top: -30px;
				left: 50%;
				content: ' ';
				display: block;
				width: 12px;
				height: 12px;
				margin-left: -9px;
				background: #fff;
				border-radius: 10px;
				border: 4px solid rgb(255,80,80);
				z-index: 10;
			}
			&:after {
				content: "";
				position: absolute;
				left: 50%;
				top: -8px;
				height: 0;
				width: 0;
				margin-left: -8px;
				border: solid transparent;
				border-bottom-color: $border-bottom-color_1;
				border-width: 8px;
				pointer-events: none;
			}
		}
		.timeWrapper {
			float: none;
		}
		.desc {
			position: relative;
			margin: 1em 1em 0 1em;
			padding: 1em;
			z-index: 15;
		}
	}
	.directionR {
		float: none;
		width: 100%;
		text-align: center;
		.flag {
			&:before {
				position: absolute;
				top: -30px;
				left: 50%;
				content: ' ';
				display: block;
				width: 12px;
				height: 12px;
				margin-left: -9px;
				background: #fff;
				border-radius: 10px;
				border: 4px solid rgb(255,80,80);
				z-index: 10;
			}
			&:after {
				content: "";
				position: absolute;
				left: 50%;
				top: -8px;
				height: 0;
				width: 0;
				margin-left: -8px;
				border: solid transparent;
				border-bottom-color: $border-bottom-color_1;
				border-width: 8px;
				pointer-events: none;
			}
		}
		.timeWrapper {
			float: none;
		}
		.desc {
			position: relative;
			margin: 1em 1em 0 1em;
			padding: 1em;
			z-index: 15;
		}
	}
	.flagWrapper {
		text-align: center;
	}
	.flag {
		background: rgb(255,255,255);
		z-index: 15;
	}
	.timeWrapper {
		display: block;
		position: relative;
		margin: 12px 0 0 0;
		z-index: 14;
		font-size: 10px;
		background-color: rgb(245,245,245);;
		border-radius: 4px;

	}
	.desc {
		position: relative;
		margin: 1em 0 0 0;
		padding: 1em;
		background: rgb(245,245,245);
		-webkit-box-shadow: 0 0 1px rgba(0,0,0,0.20);
		-moz-box-shadow: 0 0 1px rgba(0,0,0,0.20);
		box-shadow: 0 0 1px rgba(0,0,0,0.20);
		z-index: 15;
		border-radius: 4px;
		font-size: 10px;
		color: $dark;
	}
}
// @media screen and (min-width: 400px ?? max-width: 660px) {
// 	.directionL {
// 		.desc {
// 			margin: 1em 4em 0 4em;
// 		}
// 	}
// 	.directionR {
// 		.desc {
// 			margin: 1em 4em 0 4em;
// 		}
// 	}
// }


//Tilt-in style animation

.tiltInLeft {
	-webkit-animation: tiltInLeft 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: tiltInLeft 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

 @-webkit-keyframes tiltInLeft {
  0% {
    -webkit-transform: rotateX(-30deg) translateX(-300px) skewX(-30deg);
            transform: rotateX(-30deg) translateX(-300px) skewX(-30deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg) translateX(0) skewX(0deg);
            transform: rotateX(0deg) translateX(0) skewX(0deg);
    opacity: 1;
  }
}
@keyframes tiltInLeft {
  0% {
    -webkit-transform: rotateX(-30deg) translateX(-300px) skewX(-30deg);
            transform: rotateX(-30deg) translateX(-300px) skewX(-30deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg) translateX(0) skewX(0deg);
            transform: rotateX(0deg) translateX(0) skewX(0deg);
    opacity: 1;
  }
}
