@import "../../variables.modules";

h1 {
  font-size: 2rem;
  text-align: center;
  @media only screen and (min-width: 940px) {
    font-size: 3rem;
    text-align: left;
  }
}

h2 {
  font-size: 1.25rem;
  text-align: center;
  @media only screen and (min-width: 940px) {
    font-size: 2rem;
    text-align: left;
  }
}


.hand {
  animation-name: wave;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
  transform-origin: 70% 70%;
  display: inline-block;
}

a {
  color: $dark;
  transition: color 200ms ease;
}

a:hover {
  color: $lightgray;
  transition: color 200ms ease;
}

@keyframes wave {
  0% { transform: rotate( 0.0deg) }
  10% { transform: rotate(14.0deg) }
  20% { transform: rotate(-8.0deg) }
  30% { transform: rotate(14.0deg) }
  40% { transform: rotate(-4.0deg) }
  50% { transform: rotate(10.0deg) }
  60% { transform: rotate( 0.0deg) }
  100% { transform: rotate( 0.0deg) }
}

//Animation

.focusInExpand {
	-webkit-animation: focusInExpand 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: focusInExpand 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}


 @-webkit-keyframes focusInExpand {
  0% {
    letter-spacing: -0.5em;
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}
@keyframes focusInExpand {
  0% {
    letter-spacing: -0.5em;
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}



.rotateInVer {
	-webkit-animation: rotateInVer 0.5s cubic-bezier(0.470, 0.000, 0.745, 0.715) forwards;
	        animation: rotateInVer 0.5s cubic-bezier(0.470, 0.000, 0.745, 0.715) forwards;
}

 @-webkit-keyframes rotateInVer {
  0% {
    -webkit-transform: rotateY(-360deg);
            transform: rotateY(-360deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
    opacity: 1;
  }
}
@keyframes rotateInVer {
  0% {
    -webkit-transform: rotateY(-360deg);
            transform: rotateY(-360deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
    opacity: 1;
  }
}


.slideInEllipticLeftFwd {
	-webkit-animation: slideInEllipticLeftFwd 2.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slideInEllipticLeftFwd 2.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

 @-webkit-keyframes slideInEllipticLeftFwd {
  0% {
    -webkit-transform: translateX(-800px) rotateY(30deg) scale(0);
            transform: translateX(-800px) rotateY(30deg) scale(0);
    -webkit-transform-origin: -100% 50%;
            transform-origin: -100% 50%;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) rotateY(0) scale(1);
            transform: translateX(0) rotateY(0) scale(1);
    -webkit-transform-origin: 1800px 50%;
            transform-origin: 1800px 50%;
    opacity: 1;
  }
}
@keyframes slideInEllipticLeftFwd {
  0% {
    -webkit-transform: translateX(-800px) rotateY(30deg) scale(0);
            transform: translateX(-800px) rotateY(30deg) scale(0);
    -webkit-transform-origin: -100% 50%;
            transform-origin: -100% 50%;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) rotateY(0) scale(1);
            transform: translateX(0) rotateY(0) scale(1);
    -webkit-transform-origin: 1800px 50%;
            transform-origin: 1800px 50%;
    opacity: 1;
  }
}
